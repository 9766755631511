<template>
    <div class="display-2 text-center mb-5">Oida!</div>
    <h3 class="text-center">Siden du prøver å finne, eksisterer ikke</h3>
    <p class="text-center mt-5">
        Prøv å gå tilbake til den forrige siden, eller gå tilbake til <router-link to="/">hovedsiden</router-link>
    </p>
</template>
<script>
export default {
    name: "404"
}
</script>>